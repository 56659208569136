import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Grid from "@material-ui/core/Grid";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../jss/landing-webdesign.js";

import Design from "../../assets/AfterHour/after-hour-plumber-3.svg";
import Product from "../../assets/AfterHour/after-hour-plumber-2.svg";
import Install from "../../assets/AfterHour/searching.svg";
import Inspection from "../../assets/AfterHour/after-hour-plumber.svg";

const useStyles = makeStyles({
  ...styles,

  helpContainer: {
    "@media(min-width: 1950px)": {
      padding: "0px 300px 0px 300px",
    },
  },
});
export default function SectionCards() {
  const classes = useStyles();

  return (
    <div className={classNames(classes.section, classes.sectionDark)}>
      <div style={{ padding: "60px 0px 80px 0px" }}>
        <Grid container justify="center">
          <Grid item lg={10} xs={10} sm={10} md={7}>
            <h2 align="center" className={classes.title}>
              OUR PLUMBER SERVICES
            </h2>
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          alignItems="center"
          alignContent="center"
          align="center"
          className={classes.helpContainer}
        >
          <Grid item lg={3} xs={10} sm={10} md={10}>
            <Grid
              container
              justify="center"
              direction="column"
              alignItem="center"
              alignContent="center"
            >
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <div className={classes.imageContainer} align="center">
                  <Design alt="toronto-plumbers" height="128px" width="128px" />
                </div>
              </Grid>
              <Grid item lg={12} md={10} sm={11} xs={11}>
                <Grid item lg={8} md={10} sm={11} xs={11}>
                  <h3 align="center" className={classes.title2}>
                    Pipe Cleaning
                  </h3>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={3} xs={10} sm={10} md={10}>
            <Grid
              container
              justify="center"
              direction="column"
              alignItem="center"
              alignContent="center"
            >
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <div className={classes.imageContainer} align="center">
                  <Product
                    alt="toronto-plumbers"
                    height="128px"
                    width="128px"
                  />
                </div>
              </Grid>
              <Grid item lg={12} md={10} sm={11} xs={11}>
                <Grid item lg={8} md={10} sm={11} xs={11}>
                  <h3 align="center" className={classes.title2}>
                    Snaking & cleaning
                  </h3>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={3} xs={10} sm={10} md={10}>
            <Grid
              container
              justify="center"
              direction="column"
              alignItem="center"
              alignContent="center"
            >
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <div className={classes.imageContainer} align="center">
                  <Install
                    alt="toronto-plumbers"
                    height="128px"
                    width="128px"
                  />
                </div>
              </Grid>
              <Grid item lg={11} md={10} sm={11} xs={11}>
                <Grid item lg={8} md={10} sm={11} xs={11}>
                  <h3 align="center" className={classes.title2}>
                    Camera Inspection
                  </h3>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={3} xs={10} sm={10} md={10}>
            <Grid
              container
              justify="center"
              direction="column"
              alignItem="center"
              alignContent="center"
            >
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <div className={classes.imageContainer} align="center">
                  <Inspection
                    alt="toronto-plumbers"
                    height="128px"
                    width="128px"
                  />
                </div>
              </Grid>
              <Grid item lg={12} md={10} sm={11} xs={11}>
                <Grid item lg={10} md={10} sm={11} xs={11}>
                  <h3 align="center" className={classes.title2}>
                    Power Wash
                  </h3>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
